import {FormikProps} from 'formik';
import React, {useRef} from 'react';
import styled from 'styled-components';
import ModernInputWrapper from './ModernInputWrapper';
import {mediumGrayText} from 'src/utils/constants';
import useFocus from 'src/services/useFocus';

const InputTextWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  height: 56px;
  border-radius: 8px;
`;

const Prefix = styled.span`
  font-size: 16px;
  color: ${mediumGrayText};
  white-space: pre;
`;

const StyledInput = styled.input`
  flex-grow: 1;
  background-color: transparent;
  border: none;
  font-size: 20px;
  height: 100%;
  padding: 8px 4px 8px 20px;
  border-radius: 8px;
`;

const HiddenLabel = styled.label`
  width: 0;
  height: 0;
  overflow: hidden;
`;

type ModernTextInputProps = {
  prefix?: string;
  id: string;
  brandStyle: BrandStyle;
  helperText?: string;
  type?: 'text' | 'number' | 'password' | 'tel' | 'email';
  style?: React.CSSProperties;
  formikProps: Partial<FormikProps<any>>;
  placeholder?: string;
  limit?: number;
  inputStyle?: React.CSSProperties;
};

const ModernTextInput = ({
  prefix,
  id,
  brandStyle,
  helperText,
  type,
  style,
  inputStyle,
  formikProps,
  placeholder
}: ModernTextInputProps) => {
  const inputRef = useRef(undefined);
  const focused = useFocus(inputRef);

  return (
    <ModernInputWrapper
      focused={focused}
      brandStyle={brandStyle}
      errorMessage={formikProps.errors[id]}
      helperText={helperText}
      touched={formikProps.touched[id]}
      style={style}
    >
      <InputTextWrapper>
        {prefix && <Prefix>{prefix}</Prefix>}
        <HiddenLabel htmlFor={id}>{placeholder}</HiddenLabel>
        <StyledInput
          style={inputStyle}
          placeholder={placeholder}
          type={type}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          value={formikProps.values[id]}
          id={id}
          ref={inputRef}
        />
      </InputTextWrapper>
    </ModernInputWrapper>
  );
};

export default ModernTextInput;
